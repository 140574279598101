import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

import DDCLogo from '../components/DDCLogo';
import AdChoices from '../components/AdChoices';
import getFinalLogoColor from '../utils/getFinalLogoColor';

import '../global-sass/main.scss';

const Widget = () => {
	const { containerClasses, ddcLogoColor, ddcLogoThemekitOverride } =
		usePrefs();
	const { themekitFromConfig } = useSelector((state) => state.widgetData);
	const [finalLogoColor, setFinalLogoColor] = useState(
		ddcLogoThemekitOverride ? '' : ddcLogoColor
	);

	useEffect(() => {
		if (ddcLogoThemekitOverride && isBrowser) {
			let themekit = themekitFromConfig;
			const themekitFromDataLayer =
				window.DDC?.dataLayer?.site?.design?.themekit || '';

			// If we're on localhost or the _themekit URL param is present,
			// then the themekit in the DDC dataLayer could differ from the value returned from site-config-service
			if (
				themekitFromDataLayer &&
				themekitFromDataLayer !== themekitFromConfig
			) {
				themekit = themekitFromDataLayer;
			}

			setFinalLogoColor(
				getFinalLogoColor(
					ddcLogoColor,
					ddcLogoThemekitOverride,
					themekit
				)
			);
		}
	}, [ddcLogoColor, ddcLogoThemekitOverride, themekitFromConfig]);

	return finalLogoColor ? (
		<div className={setClassNames(containerClasses.split(' '))}>
			<DDCLogo color={finalLogoColor} />
			<AdChoices />
		</div>
	) : null;
};

export default Widget;
